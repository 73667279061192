import React from "react";
import styled from "styled-components";

const Section = styled.div`
  align-items: center;
  background-color: ${({ bgColor }) => `${bgColor}`};
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  padding: 50px 0;
  overflow: hidden;
  width: 100vw;

  h1 {
    background: ${({ textColor, deg }) =>
      `linear-gradient(${deg}deg, white, ${textColor})`};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 4rem;
    font-weight: 600;
    width: 90vw;
    max-width: 1000px;
  }

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 90%;
    width: 100%;
  }

  .col2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
  }

  img {
    max-width: 90vw;
  }

  .desc {
    font-size: 1.3rem;
  }
`;

const Page2col = ({
  bgColor = "black",
  color = "white",
  textColor = "darkRed",
  deg = 90,
  children,
  sub = "",
  title = "title",
  desc = "description",

  right = false,
}) => {
  return (
    <Section bgColor={bgColor} textColor={textColor} deg={deg}>
      <h1>{title}</h1>
      <div>
        {!right && children}
        <div className="col2">
          {sub && <h3>{sub}</h3>}
          <div className="desc">{desc}</div>
        </div>
        {right && children}
      </div>
    </Section>
  );
};

export default Page2col;
