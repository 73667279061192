import React from "react";
import styled from "styled-components";

const Section = styled.section`
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 100px;
  width: 100vw;

  div {
    font-size: 1.2rem;
    max-width: 1000px;
    color: lightgray;
  }

  h1 {
    background: ${({ deg, col1, col2, col3 }) =>
      `linear-gradient(${deg}deg, ${col1}, ${col2}, ${col3})`};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 4rem;
    font-weight: 600;
    width: 90vw;
    max-width: 1000px;
  }

  h2 {
    max-width: 1000px;
    font-size: 2rem;
  }

  p {
    font-size: 1.3rem;
    max-width: 1000px;
  }
`;
const Page = ({
  title = "Title",
  sub = "",
  desc = "description",
  children,
  deg = "90",
  col1 = "white",
  col2 = "royalblue",
  col3 = "blue",
}) => {
  return (
    <Section deg={deg} col1={col1} col2={col2} col3={col3}>
      <h1>{title}</h1>
      {children}
      {sub && <h2>{sub}</h2>}
      <div>{desc}</div>
    </Section>
  );
};

export default Page;
