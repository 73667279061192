import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faApple } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Btn = styled.button`
  align-items: center;
  background-color: ${({ bgColor }) => `${bgColor}`};
  border: 0.8px solid white;
  border-radius: 25px;
  color: ${({ textColor }) => `${textColor}`};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  height: 2.5rem;
  justify-content: center;
  margin: 20px;
  min-width: 100px;
  padding: 0 1rem;

  .icon {
    margin-right: 10px;
    font-size: 1.2rem;
  }
`;

const DownloadBtn = ({
  Google,
  bgColor = "transparent",
  textColor = "white",
}) => {
  const [t] = useTranslation();
  const link = Google
    ? "https://play.google.com/store/apps/details?id=com.dmicano.angel1sma3l.cartondeapunte&hl=en_US"
    : "https://itunes.apple.com/us/app/domino-apunte/id1385265662?mt=8";

  const iconName = Google ? faGoogle : faApple;

  return (
    <a href={link} style={{ textDecoration: "none" }}>
      <Btn bgColor={bgColor} textColor={textColor}>
        <FontAwesomeIcon icon={iconName} className="icon" />
        {t("download") ?? "Download"}
      </Btn>
    </a>
  );
};

export default DownloadBtn;
