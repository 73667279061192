import logo from "../Assets/logo.png";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import DownloadBtn from "./Download/DownloadBtn";
import { useEffect, useRef } from "react";
import useVideoIsVisible from "../hooks/useVideoIsVisible";

const sliding = keyframes`
0% {
  background-position: left;
}

100% {
  background-position: right;
}
`;

const Header = styled.header`
  align-items: center;
  background-color: #00000099;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  /* background: var(--gradient);
  background-size: 1200%;
  background-position: left;
  animation: 20s linear 0s infinite alternate ${sliding}; */

  padding: 50px auto;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  h1 {
    /* margin: 50px 0; */
    font-size: 3.8rem;
    font-weight: bold;
    max-width: 1000px;
    text-align: center;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      a {
        transition: transform 300ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 2.8rem;
    }
  }
`;

const beat = keyframes`
0% {
     scale: 0.8; 
    }
    
  /* 30% {
    scale: 1;
  } */
  50% {
    scale: 1;
  }
  /* 70% {
    scale: 1;
  } */
  100% {
     scale: 0.8;
  }
`;

const heartbeatSound = require("../Assets/heartBeat.mp3");

const AnimatedImg = styled.div`
  display: flex;
  animation: 1s infinite ${beat};
`;

const AppHeader = () => {
  const { t } = useTranslation("translation");
  const soundRef = useRef();

  const isVisible = useVideoIsVisible(soundRef);

  useEffect(() => {
    isVisible ? soundRef.current.play() : soundRef.current.pause();
    soundRef.current.volume = 0.1;
  }, [soundRef, isVisible]);
  console.log(isVisible);

  return (
    <Header>
      <h1>{t("headerTitle")}</h1>

      <section>
        <AnimatedImg>
          <img
            src={logo}
            style={{ width: "4rem", height: "4rem" }}
            alt="DA logo"
          />
        </AnimatedImg>

        <div className="row">
          <DownloadBtn />
          <DownloadBtn Google />
        </div>
        <audio
          ref={soundRef}
          src={heartbeatSound}
          controls
          loop
          style={{ zIndex: -1 }}
        />
      </section>
    </Header>
  );
};

export default AppHeader;
