import React, { useRef } from "react";
import styled from "styled-components";
import useVideoIsVisible from "../hooks/useVideoIsVisible";

const vid = require("../Assets/playaAd.mp4");

const Div = styled.div`
  background-color: black;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;

  video {
    width: 100%;
    height: auto;
  }
`;

const PlayaVideoSection = ({ children, refe }) => {
  const videoRef = useRef();
  useVideoIsVisible(videoRef);

  return (
    <Div>
      <video ref={videoRef} autoPlay muted loop playsInline>
        <source src={vid} type="video/mp4" />
        <source src={vid} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      {children}
    </Div>
  );
};

export default PlayaVideoSection;
