import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import UserProvider from "./context/userContext";
// import Banner from "./Components/Banner";

const App = () => {
  return (
    <UserProvider>
      <main style={{ overflow: "hidden" }}>
        <Nav />
        {/* <Banner /> */}
        <Outlet />
        <Footer />
      </main>
    </UserProvider>
  );
};

export default App;
