import { useEffect, useState } from "react";

const useVideoIsVisible = (videoRef) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    });

    observer.observe(videoRef.current);

    if (isVisible) {
      videoRef.current.load();
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }

    return () => {
      observer.disconnect();
    };
  }, [videoRef, isVisible]);

  return isVisible;
};

export default useVideoIsVisible;
