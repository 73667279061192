import styled from "styled-components";

const Div = styled.div`
  align-items: center;
  background-color: black;
  border-radius: 40px;
  display: flex;
  color: white;
  box-shadow: 5px 5px 20px blue;
  flex-direction: column;
  height: 10rem;
  justify-content: center;
  padding: 25px 0;
  width: 30%;

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    /* width: 90%; */
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 0 10px;
  }
`;

const Card = ({ title = "title", desc = "desc", icon }) => {
  return (
    <Div>
      <div>
        <h1>{title}</h1>
        {icon}
      </div>
      <p>{desc}</p>
    </Div>
  );
};

export default Card;
