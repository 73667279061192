import React, { useRef } from "react";
import styled from "styled-components";
import useVideoIsVisible from "../hooks/useVideoIsVisible";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 0;
  overflow: hidden;

  video {
    width: auto;
    height: 100%;
    padding: 0;
    margin: 0;
  }
`;

const vid = require("../Assets/howToUseDA.mov");

const HowToUseDA = () => {
  const videoRef = useRef();
  useVideoIsVisible(videoRef);

  return (
    <Container>
      <video ref={videoRef} muted playsInline>
        <source src={vid} type="video/mov" />
        <source src={vid} type="video/webm" />
        your browser does not support this video tag.
      </video>
    </Container>
  );
};

export default HowToUseDA;
