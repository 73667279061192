import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Article = styled.article`
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 100px 0;
  height: 90vh;

  h1 {
    font-size: 4rem;
    font-weight: bold;
    max-width: 90%;
    margin: 0;
  }

  p {
    width: 90%;
    max-width: 800px;
    /* font-weight: 300; */
    font-size: 1.05rem;
  }
`;

const DominoHistory = () => {
  const { t } = useTranslation();
  return (
    <Article>
      <h1>{t("dominoHistory.title")}</h1>

      <p>
        {t("dominoHistory.description.part1")}
        <br />
        <br />
        {t("dominoHistory.description.part2")}

        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.jaqueslondon.co.uk/blogs/posts/history-of-games-dominoes#:~:text=Dominoes%20are%20most%20likely%20invented,commonly%20made%20from%20heavy%20plastic."
        >
          {" "}
          Jaques London
        </a>
      </p>
    </Article>
  );
};

export default DominoHistory;
