import { useRef } from "react";
import styled from "styled-components";
import { AboutHeader } from "./AboutHeader";
import Card from "./AboutCard";
import { useTranslation } from "react-i18next";
import Page from "../page";
import Page2col from "../page2col";
import Slider from "../Slider";
import useVideoIsVisible from "../../hooks/useVideoIsVisible";
import HowToUseDA from "../HowToUseDA";
// import Page2col from "../page2col";

const Div = styled.div`
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  min-height: 80vh;

  article {
    background-color: black;
    color: white;
    text-align: center;
    padding: 50px;
  }
  .row {
    /* align-items: center; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 100px 0;
    width: 100vw;
  }

  .what-is-dominoapunte {
    margin: 100px auto;
    width: 85%;

    h3 {
      font-weight: bold;
      font-size: 2.5rem;
      text-align: center;
      color: red;
    }

    ul {
      li {
        /* color: red; */
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }
`;

const starIcon = (
  <i
    className="fa-solid fa-star"
    style={{ fontSize: "1.8rem", marginLeft: 5 }}
  ></i>
);

// const settingScreen = require("../../Assets/iphoneSettingsScreen.png");
const gameModesVideo = require("../../Assets/gameModesVideo.mov");
const homeScreen = require("../../Assets/iphoneHomeScreen.png");
const historyScreen = require("../../Assets/iphoneHistory.png");
const playersFocused = require("../../Assets/IMG_2745.PNG");
const googleAndAppleScreen = require("../../Assets/googleAndApple.png");

const About = () => {
  const [t] = useTranslation();
  const video1Ref = useRef();
  useVideoIsVisible(video1Ref);
  const slides = [
    {
      image: historyScreen,
      title: t("whyShouldUseIt.title4"),
      list1: t("whyShouldUseIt.4.1"),
      list2: t("whyShouldUseIt.4.2"),
    },
    {
      image: googleAndAppleScreen,
      title: t("whyShouldUseIt.title5"),
      list1: t("whyShouldUseIt.5.1"),
      list2: t("whyShouldUseIt.5.2"),
    },
    {
      image: homeScreen,
      title: t("whyShouldUseIt.title6"),
      list1: t("whyShouldUseIt.6.1"),
      list2: t("whyShouldUseIt.6.2"),
    },
    {
      image: playersFocused,
      title: t("whyShouldUseIt.title7"),
      list1: t("whyShouldUseIt.7.1"),
      list2: t("whyShouldUseIt.7.2"),
    },
    {
      image: historyScreen,
      title: t("whyShouldUseIt.title4"),
      list1: t("whyShouldUseIt.4.1"),
      list2: t("whyShouldUseIt.4.2"),
    },
  ];
  // const [isMobile, setIsMobile] = useState(false);

  return (
    <Div>
      <AboutHeader />

      <article>
        <h1>{t("whyShouldUseIt.title")} </h1>
        <h3>{t("whyShouldUseIt.description")}</h3>
      </article>

      <Page
        title={t("whyShouldUseIt.title1")}
        sub={""}
        desc={
          <ul>
            <li>{t("whyShouldUseIt.1.1")}</li>
            <li>{t("whyShouldUseIt.1.2")}</li>
          </ul>
        }
      >
        {/* <img
          src={homeScreenLeft}
          alt="home screen"
          width="auto"
          height="800px"
          style={{ maxWidth: "100vw" }}
        /> */}
        <HowToUseDA />
      </Page>

      <Page
        title={t("whyShouldUseIt.title2")}
        deg={150}
        col1="purple"
        col2="white"
        col3="purple"
        desc={
          <ul>
            <li>{t("whyShouldUseIt.2.1")}</li>
            <li>{t("whyShouldUseIt.2.2")}</li>
          </ul>
        }
      >
        <video
          ref={video1Ref}
          loop
          muted
          playsInline
          style={{
            maxWidth: "100vw",
            width: 500,
            height: "auto",
          }}
        >
          <source src={gameModesVideo} type="video/mov" />
          <source src={gameModesVideo} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </Page>

      <Page2col
        title={t("whyShouldUseIt.title3")}
        desc={
          <ul>
            <li>{t("whyShouldUseIt.3.1")}</li>
            <li>{t("whyShouldUseIt.3.2")}</li>
          </ul>
        }
        right
      >
        <img
          src={homeScreen}
          alt="DA home screen"
          height="500px"
          width="auto"
        />
      </Page2col>

      <Slider data={slides} auto delay={8000} />

      <div className="what-is-dominoapunte">
        <h3>{t("whatIsDomino.whatIs")}</h3>
        <h4>{t("whatIsDomino.features")}</h4>
        <ul>
          <li>{t("whatIsDomino.list.1")}</li>
          <li>{t("whatIsDomino.list.2")}</li>
          <li>{t("whatIsDomino.list.3")}</li>
          <li>{t("whatIsDomino.list.4")}</li>
        </ul>
        <h4>{t("whatIsDomino.end")}</h4>
      </div>

      <div className="row">
        <Card title="1M" desc={t("aboutCards.1M")} />
        <Card title="#1" desc={t("aboutCards.#1")} />
        <Card icon={starIcon} title="4.9" desc={t("aboutCards.4.9")} />
      </div>
    </Div>
  );
};

export default About;
