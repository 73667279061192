import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DownloadBtns from "../Download/DownloadBtns";

const Container = styled.section`
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 200px;
  width: 100vw;

  h1 {
    font-weight: bold;
    background: linear-gradient(355deg, white, red, darkred, black);
    background-clip: text;
    color: transparent;
    font-size: 6rem;
    max-width: 1000px;
    /* margin-top: 20px; */
    text-align: center;
    -webkit-background-clip: text;
    width: 90%;
  }

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    max-height: vh;
    max-width: 800px;
    overflow: scroll;
    width: 100%;
  }

  img {
    margin-top: 100px;
    max-width: 40%;
  }
`;

const image = require("../../Assets/iphoneHomeScreen.png");
const image1 = require("../../Assets/Pixel4XL.png");

export const AboutHeader = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h1>{t("aboutHeader")}</h1>
      <div>
        <img src={image} width="300px" height="auto" alt="iPhone Screen" />
        <img src={image1} width="300px" height="auto" alt="Android Screen" />
      </div>
      <DownloadBtns />
    </Container>
  );
};
