import React from "react";
import DownloadBtn from "./DownloadBtn";

const DownloadBtns = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <DownloadBtn />
      <DownloadBtn Google />
    </div>
  );
};

export default DownloadBtns;
