import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const Main = styled.div`
  min-height: ${({ height }) => height};
  margin: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  padding: 0;
  width: ${({ width }) => width};

  &:hover {
    box-shadow: ${({ hoverOn }) => (hoverOn ? "0px 0px 20px gray" : "none")};
  }

  .wrapper {
    /* min-height: ${({ height }) => height}; */
    display: flex;
    transition: ${({ slideNum }) =>
      slideNum === 0 ? "none" : "transform 1s ease-out"};
    transform: ${({ slideNum, qty }) =>
      // divide 100% into qty of images to find the width of each slide then
      // multiply it by slide number. all negative to translate to the left.
      "translateX(-" + ((100 / qty) * slideNum + "%)")};
    width: ${({ qty }) => qty * 100}%;
    /* overflow: scroll; */

    .slide {
      background-color: ${({ bgColor }) => bgColor};
      height: ${({ height }) => height};
      width: ${({ width }) => width};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .row {
      align-items: center;
      display: flex;
      justify-content: space-around;
      padding: 0;
      width: 100%;

      .imgContainer {
        text-align: center;
        object-fit: contain;
        text-align: center;
        height: 500px;
        width: 60%;
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }

      ul {
        width: 40%;
        padding-left: -10px;
        font-size: 1.3rem;
      }
    }

    @media only screen and (max-width: 600px) {
      .row {
        flex-wrap: wrap;
        .imgContainer {
          height: 300px;
          width: 100%;
        }
        ul {
          width: 100%;
          margin-top: -10px;
        }
      }
    }

    h1 {
      margin: 20px 5px;
      max-width: 1000px;
    }
  }
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: absolute;
  bottom: 20px;
  width: 100%;
`;

const Dot = styled.div`
  display: flex;
  gap: 5px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ active }) => (active ? "white" : "gray")};
  transition: all 0.3s ease-in;
`;

const Slider = ({
  width = "100vw",
  height = "110vh",
  data = [],
  auto,
  delay = 3000,
  hoverOn,
  bgColor = "black",
}) => {
  const imgQty = data?.length;
  const slideRef = useRef();
  const [slideNum, setSlideNum] = useState(0);
  const dots = useMemo(
    () => [
      ...data.map((_, index) => {
        return { id: index };
      }),
    ],
    [data]
  );

  useEffect(() => {
    let index = 0;
    let interv = null;

    // restarting when is visible
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setSlideNum(0);
        index = 0;
      }
    });

    if (auto) {
      observer.observe(slideRef.current);

      interv = setInterval(() => {
        index = index < imgQty - 1 ? (index += 1) : 0;
        setSlideNum(index);
      }, delay);
    }

    return () => {
      clearInterval(interv);
      observer.disconnect();
    };
  }, [auto, delay, imgQty]);

  return (
    <Main
      ref={slideRef}
      bgColor={bgColor}
      hoverOn={hoverOn}
      height={height}
      slideNum={slideNum}
      qty={imgQty}
      width={width}
    >
      <div className="wrapper">
        {data.map((item, index) => (
          <div className="slide" key={index}>
            <h1>{item.title}</h1>
            <div className="row">
              <div className="imgContainer">
                <img src={item.image} alt="DA screens" />
              </div>
              <ul>
                <li>{item.list1}</li>
                <br />
                <li>{item.list2}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>

      <DotsContainer>
        {dots.map((item) => (
          <Dot
            itemNum={item.id}
            active={slideNum === item.id}
            key={item.id}
          ></Dot>
        ))}
      </DotsContainer>
    </Main>
  );
};

export default Slider;
